@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Main container: black background, fade in, vertical layout */
.bio-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Increase top padding so the Go Back button never overlaps text */
  padding-top: 5rem; /* Adjust this value as needed */
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  min-height: 100vh; 
  background: #000; 
  animation: fadeIn 0.5s ease-in-out forwards;
  color: #fff;
}

/* Go Back Button: absolutely positioned top-left */
.go-back-btn {
  position: absolute; 
  top: 1rem;
  left: 1rem;
  background-color: #000;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.2);
  transition: background-color 1.5s ease;
  color: #fff;
}

.go-back-btn:hover {
  background-color: #333;
}

.go-back-icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

/* "About Us" Title: white text on black */
.bio-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem; 
  color: #fff;
}

/* Columns stack vertically by default */
.bio-text-row {
  display: flex;
  flex-direction: column; 
  gap: 1rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  align-items: center; 
}

/* White glow box-shadow on each column */
.bio-column {
  width: 100%;
  padding: 1rem;
  background-color: #000;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.4); /* white glow */
  transition: transform 1.5s ease, box-shadow 1.5s ease;
}

.bio-column:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.6);
}

/* Paragraph text */
.bio-column p {
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 0;
  color: #fff;
}

/* Bottom logo container in normal flow */
.bottom-logo-container {
  margin-top: 2rem; 
  text-align: center;
  animation: floatLogo 3s ease-in-out infinite;
}

.bottom-logo-container img {
  width: 300px; 
  display: block;
  margin: 0 auto;
}

.bottom-logo-container h1 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  color: #fff;
}

/* Icon links container */
.bio-links {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  justify-content: center;
}

.bio-link i {
  font-size: 2rem;
  color: #fff;
  transition: color 1.5s ease;
}

.bio-link i:hover {
  color: #aaa;
}

/* Floating animation (optional) */
@keyframes floatLogo {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

/* If you want the button or logo in normal flow on smaller screens */
@media (max-width: 768px) {
  .go-back-btn {
    position: static;
    margin-bottom: 1rem;
    box-shadow: none;
  }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.fade-out {
  animation: fadeOut 0.5s ease-out forwards;
}

