.homepage-container {
  position: relative;
  min-height: 100vh;
  /* If you already have a global background in App.js, you can leave this out.
     Otherwise, add your background here:
     background: url("/Embrace/aliefe2.jpeg") no-repeat center center;
     background-size: cover;
  */
}

/* Desktop: Show these sections by default, hide on mobile */
.desktop-view {
  display: block;
  position: relative;
  min-height: 100vh;
}

.desktop-logo {
  position: absolute;
  bottom: 2rem; /* for example */
  left: 50%;
  transform: translateX(-50%);
}

.desktop-logo img {
  width: 300px;  /* or bigger if you want */
}

.desktop-embrace-text {
  position: absolute;
  bottom: 0.5rem; /* just above or below the logo */
  left: 50%;
  transform: translateX(-50%);
}

.desktop-embrace-text img {
  width: 250px;  /* adjust as needed */
}

/* Mobile: hidden by default, shown on smaller screens */
.mobile-view {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.mobile-logo img {
  width: 200px; /* or whatever size you like */
  margin: 0.5rem 0;
}

/* Mobile Buttons */
.mobile-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 80%;
  max-width: 300px;
  margin: 2rem auto 0;
}

.mobile-btn {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  padding: 1rem;
  font-size: 1.2rem;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
  text-align: center;
  text-decoration: none; /* remove underline for links */
}

.mobile-btn:hover {
  background: #fff;
  color: #000;
}

/* -- Media Query: Switch to Mobile Layout -- */
@media (max-width: 768px) {
  .desktop-view {
    display: none; /* hide the desktop version */
  }
  .mobile-view {
    display: flex; /* show the mobile version */
  }
}

@media (max-width: 1024px) {
  .desktop-view {
    display: none; /* hide the desktop version */
  }
  .mobile-view {
    display: flex; /* show the mobile version */
  }
}