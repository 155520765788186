/* Example App.css */

/* Nav container styling */
.nav-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Nav group (left, right) */
.nav-group {
  display: flex;
  gap: 1.5rem;
}

/* Nav link + button styling */
.nav-link {
  text-decoration: none;
}

.nav-btn {
  background: none;
  color: #fff;
  border: 1px solid #fff;
  padding: 0.5rem 3rem;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, color 0.3s ease;
  outline: none;
}

.nav-btn:hover {
  background: #fff;
  color: #000;
}

/* Logo styling */
.logo-img {
  height: 300px;
  object-fit: contain;
}

/* Adjust for mobile if needed */
@media (max-width: 768px) {
  .nav-container {
    flex-direction: column;
    gap: 1rem;
  }
  .logo-img {
    height: 200px;
  }
}

@media (max-width: 1024px) {
  .nav-group {
    display: none;
  }
  .logo-img {
    height: 300px;
    object-fit: contain;
  }
  .nav-container {
    flex-direction: column;
    gap: 1rem;
  }
}
