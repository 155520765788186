/* src/components/MediaDropdown.css */

.media-dropdown {
  position: relative; /* so .dropdown-menu can be positioned absolutely */
  display: inline-block;
}

/* Header button styling (used on desktop) */
.nav-btn {
  background: none;
  color: #fff;
  border: 1px solid #fff;
  padding: 0.5rem 3rem; /* increased padding for wider buttons */
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.25rem; /* slightly larger font size */
  transition: background 0.3s ease, color 0.3s ease;
  outline: none;
}

.nav-btn:hover {
  background: #fff;
  color: #000;
}

/* Mobile button styling (to match your other mobile buttons) */
.mobile-btn {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  padding: 1rem;
  font-size: 1.2rem;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
  text-align: center;
  text-decoration: none;
}

.mobile-btn:hover {
  background: #fff;
  color: #000;
}

/* Dropdown menu container */
.dropdown-menu {
  position: absolute;
  top: 4.5rem; /* position below the button */
  right: 0;    /* align to the right side of the button */
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  padding: 1rem;
  border-radius: 8px;
  min-width: 150px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.3);
  z-index: 999;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.media-dropdown .dropdown-menu {
  opacity: 1;
  transform: translateY(0);
}

/* Dropdown text and link styling */
.dropdown-title {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #444;
}

.dropdown-subtitle {
  font-size: 0.8rem;
  font-weight: bold;
  margin: 1rem 0 0.5rem;
  color: #aaa;
}

.dropdown-item {
  display: block;
  margin: 0.25rem 0;
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.dropdown-item i {
  margin-right: 0.5rem; /* space between icon and text */
}

.dropdown-item:hover {
  background: rgba(0,0,0,0.1);
  border-radius: 4px;
  padding-left: 0.25rem;
}
